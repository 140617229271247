export const modal = () => {
  const header = document.querySelector("#header");
  const body = document.querySelector("body");
  const modal = document.querySelector(".backdrop-modal");
  const modalButtonClose = document.querySelectorAll(".modal-close");
  const modalButtons = document.querySelectorAll(".modal");
  modalButtons.forEach((modalButton) => {
    modalButton.addEventListener("click", (e) => {
      e.preventDefault();

      const modalWrapper = document.querySelector(".modal-main");
      modal.classList.remove("is-hidden");
      body.classList.add("overflow-hidden");
      modalButtonClose.forEach((e) => {
        e.addEventListener("click", () => {
          modal.classList.add("is-hidden");
          body.classList.remove("overflow-hidden");
        });
      });
    });
  });
};
