import { contactForm } from "./modules/contact-form.js";
import { sliders } from "./modules/sliders.js";
import { accordion } from "./modules/accordion.js";
import { burger } from "./modules/header-burger.js";
import { modal } from "./modules/modal.js";
import { anchors } from "./modules/anchors.js";
import { copy } from "./modules/copy.js";
document.addEventListener("DOMContentLoaded", function (event) {
  contactForm();
  sliders();
  accordion();
  burger();
  modal();
  anchors();
  copy();
});
