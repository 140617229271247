export const contactForm = () => {
  const calendarTitle = document.querySelector(".first-screen-form-date");
  const options = {
    dateFormat: "Y-m-d", // Формат дати
    disableMobile: true, // Відключення мобільного календаря
  };
  const datepicker = flatpickr(".date input", options);
  const mobileButtonContainer = document.querySelector(".first-screen-mobile-button");
  const mobileFormContainer = document.querySelector(".first-screen-mobile-form");
  if (mobileButtonContainer) {
    const mobileButton = mobileButtonContainer.querySelector("button");
    if (mobileButton && mobileFormContainer) {
      mobileButton.addEventListener("click", (e) => {
        mobileButtonContainer.classList.add("hidden");
        mobileFormContainer.classList.remove("hidden");
      });
    }
  }
  const forms = document.querySelectorAll(".first-screen-form-input.select");

  forms.forEach(function (form) {
    const titleInput = form.querySelector(".first-screen-form-input-select-title");
    const selectList = form.querySelector(".first-screen-form-input-select-list");
    const valueInput = form.querySelector(".first-screen-form-input-select-container input");

    titleInput.addEventListener("click", function () {
      closeAllSelectLists();
      selectList.classList.toggle("hidden");
    });

    selectList.addEventListener("click", function (event) {
      if (event.target.tagName === "LI") {
        valueInput.value = event.target.textContent;
        titleInput.textContent = event.target.textContent;
        // Видаляємо клас "selected" з усіх елементів списку
        const allListItems = selectList.querySelectorAll("li");
        allListItems.forEach(function (item) {
          item.classList.remove("selected");
        });

        // Додаємо клас "selected" до вибраного елемента
        event.target.classList.add("selected");
        selectList.classList.add("hidden");
      }
    });

    document.addEventListener("click", function (event) {
      if (!form.contains(event.target)) {
        selectList.classList.add("hidden");
      }
    });
  });

  function closeAllSelectLists() {
    forms.forEach(function (form) {
      const selectList = form.querySelector(".first-screen-form-input-select-list");
      selectList.classList.add("hidden");
    });
  }

  const modalForms = document.querySelectorAll(".modal-form-select-container");

  modalForms.forEach(function (form) {
    const selectList = form.querySelector(".modal-form-select-container ul");
    const valueInput = form.querySelector(".modal-form-select-input input");

    selectList.addEventListener("click", function (event) {
      if (event.target.tagName === "LI") {
        valueInput.value = event.target.textContent;

        // Видаляємо клас "selected" з усіх елементів списку
        const allListItems = selectList.querySelectorAll("li");
        allListItems.forEach(function (item) {
          item.classList.remove("selected");
        });

        // Додаємо клас "selected" до вибраного елемента
        event.target.classList.add("selected");
      }
    });

    // При зміні полів вводу видалити клас "selected" зі списку
    valueInput.addEventListener("input", function () {
      const allListItems = selectList.querySelectorAll("li");
      allListItems.forEach(function (item) {
        item.classList.remove("selected");
      });
    });
  });

  const emailForm = document.querySelector(".email-form .wpcf7");
  if (emailForm) {
    emailForm.addEventListener(
      "wpcf7mailsent",
      function (event) {
        let wrapper = emailForm.closest("#email");
        wrapper.classList.add("show");
      },
      false
    );
  }

  const ThanksForms = document.querySelectorAll(".thanks .wpcf7");
  ThanksForms.forEach((form) => {
    form.addEventListener(
      "wpcf7mailsent",
      function (event) {
        window.location.href = "/thanks/";
      },
      false
    );
  });
};
