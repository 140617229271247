export const copy = () => {
  const copyLinkElement = document.getElementById("copyLink");
  if (copyLinkElement) {
    copyLinkElement.onclick = function (event) {
      event.preventDefault(); // Зупинити стандартну дію по переходу за посиланням

      const linkToCopy = copyLinkElement.href;

      const tempInput = document.createElement("input");
      document.body.appendChild(tempInput);
      tempInput.value = linkToCopy;
      tempInput.select();
      document.execCommand("copy");

      document.body.removeChild(tempInput);

      alert("Link copied!");
    };
  }
};
