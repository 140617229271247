export const accordion = () => {
  $(".faq-item:first-child").addClass("active");
  $(".faq-item").each(function () {
    $(this).on("click", function () {
      var $faqItem = $(this);
      $faqItem.siblings(".faq-item").removeClass("active").find(".faq-item-answer").slideUp(250);
      $faqItem.find(".faq-item-answer").slideToggle(250);
      $faqItem.toggleClass("active");
    });
  });
};
