import Swiper from "swiper/bundle";
export const sliders = () => {
  const teamList = document.querySelectorAll(".team-carousel-list");
  teamList.forEach((item) => {
    const swiperContainer = new Swiper(item, {
      direction: "horizontal",
      slidesPerView: "auto",
      freeMode: true,
      grabCursor: true,
      initialSlide: 1,
      spaceBetween: 10,
      loop: true, // Залиште loop на true
      autoplay: {
        delay: 0,
        disableOnInteraction: false,
      },
      speed: 2000,
    });

    // Отримуємо посилання на .swiper-wrapper
    const wrapper = item.querySelector(".swiper-wrapper");
    // Отримуємо кількість слайдів
    const slides = wrapper.querySelectorAll(".swiper-slide").length;
    // Дублюємо слайди в кінці .swiper-wrapper
    for (let i = 0; i < slides; i++) {
      const originalSlide = wrapper.querySelector(".swiper-slide:nth-child(" + (i + 1) + ")");
      const clonedSlide = originalSlide.cloneNode(true);
      wrapper.appendChild(clonedSlide);
    }
    // Оновлюємо Swiper після додавання дублікатів
    swiperContainer.update();
  });
  const reviewsList = document.querySelectorAll(".reviews-list");
  reviewsList.forEach((item) => {
    const instance_swiper = new Swiper(item, {
      direction: "horizontal", // Горизонтальна прокрутка
      slidesPerView: 1,
      spaceBetween: 10,
      loop: false, // Зациклення слайдів
      // autoplay: {
      //   delay: 3000, // Без затримки перед переходом до наступного слайда
      //   disableOnInteraction: false,
      // },
      // Navigation arrows
      navigation: {
        nextEl: ".reviews-swiper-nav-next",
        prevEl: ".reviews-swiper-nav-prev",
      },

      speed: 0, // Швидкість анімації (у мілісекундах)

      breakpoints: {
        768: {
          spaceBetween: 10,
          slidesPerView: "auto",
          grabCursor: true,
          freeMode: true,
        },
      },
    });
  });

  const expertsList = document.querySelectorAll(".experts-list-mobile");
  expertsList.forEach((item) => {
    const instance_swiper = new Swiper(item, {
      direction: "horizontal", // Горизонтальна прокрутка
      slidesPerView: 1,
      loop: false, // Зациклення слайдів
      // autoplay: {
      //   delay: 3000, // Без затримки перед переходом до наступного слайда
      //   disableOnInteraction: false,
      // },
      // Navigation arrows
      navigation: {
        nextEl: ".experts-list-mobile-nav-next",
        prevEl: ".experts-list-mobile-nav-prev",
      },

      speed: 1000, // Швидкість анімації (у мілісекундах)

      breakpoints: {
        768: {
          spaceBetween: 10,
          slidesPerView: 2,
        },
      },
    });
  });

  const openButtons = document.querySelectorAll(".experts-item-mobile-link-open");
  if (openButtons) {
    openButtons.forEach((openButton) => {
      openButton.addEventListener("click", (e) => {
        const closestWrapper = openButton.closest(".experts-item-mobile");
        if (!closestWrapper.classList.contains("is-open")) closestWrapper.classList.add("is-open");
        else return;
        const closeButton = closestWrapper.querySelector(".experts-item-mobile-link-close");
        closeButton.addEventListener("click", (elClose) => {
          if (closestWrapper.classList.contains("is-open")) closestWrapper.classList.remove("is-open");
        });
      });
    });
  }

  const modalSlider = document.querySelectorAll(".modal-slider");
  modalSlider.forEach((item) => {
    const instance_swiper = new Swiper(item, {
      direction: "horizontal", // Горизонтальна прокрутка
      slidesPerView: 1,
      loop: false, // Зациклення слайдів
      // autoplay: {
      //   delay: 3000, // Без затримки перед переходом до наступного слайда
      //   disableOnInteraction: false,
      // },
      // Navigation arrows
      // effect: "fade",
      navigation: {
        nextEl: ".modal-slider-nav-next",
        prevEl: ".modal-slider-nav-prev",
      },

      speed: 0, // Швидкість анімації (у мілісекундах)
    });
  });
};
